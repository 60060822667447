
.navbar {
    background-color: #007000;
    /* height:80px; */
    display: flex;
    justify-content: start;
    align-items: center;
    /* margin-left: 250px; */
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #007000;
    display: flex;
    justify-content: center;
    padding-top: 270px;
    position: fixed;
    left: 0;
    width: 250px;
    height: 100vh;
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-links{
    /* justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px; */
    list-style: none;
    
    /* height: 60px; */
    
    /*  */
    margin-top: 0;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    /* width: 95%; */
    height: 100%;
    /* display: inline; */
    /* align-items: center; */
    /* padding: 0 16px; */
    border-radius: 4px;

    /*  */
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 20px;
    /* border: 1px solid red; */

}

.nav-links a:hover{
    color: #ffffff;
    /* background-color: #53a9d4; */
    text-decoration: none;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #007000;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

span {
    margin-left: 18px;
}


@media only screen and (max-width: 1200px){
    .nav-menu{
        width: 36px;
        z-index: 150;
        
    } 
    .nav-links span:last-child{
        display: none;
    }

    .nav-links a{
        /* background-color: red; */
        /* margin-left: -100; */
        padding-left: .8rem;
    }

    /* .profile-pic{
        display: none;
    } */
    
    #sidebar-toggle:checked ~ .nav-menu{
        width: 250px;
        
    }
    
}