@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.container{
    display: none;
}

.img{
    img{
        border-radius: 75px;
    width: 100%;
    height: 24rem;
    }
}

.description{
    width: 95%;
    padding: 1rem;
    height: auto;
    border-radius: 25px;
    letter-spacing: normal;
    border:1px solid #CAFFCA;
    // background: red;
}

.reviews{
    margin-top: 2rem;
    width: 100%;
    height: auto;
    // background: red;
    padding: 1rem;
    .single-review{
        // background: chartreuse;
        margin-left: 4rem;
        padding: 1rem;
        margin-top: .5rem;
        list-style: none;
        // border: 1px solid #007000;
        background: white;
        // background: #ccc;
        // border-bottom: 1px solid #ccc;
        // border-top: 1px solid #ccc;
        // border: 1px solid #ccc;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        .review-header{
            // border: 1px solid red;
            margin-bottom: 1rem;
            .name, .remedy{
                // display: inline-block;
                color: #007000;
                margin-right: 1rem;
                font-weight: bolder;
            }
            .date, .reviewd{
                color:#837A7A;
            }
        }
        .review-body{
            // border: 1px solid blue;
            padding: 0 2rem 1rem 2rem;
            letter-spacing: normal;
        }
        .review-footer{
            border-top: 1px solid;
            display: flex;
            justify-content:left;
            padding-left: 1rem;
            .rating{
                color: #007000;
                font-size: 3rem;
            }
            .comments{
                padding-left: 1rem;
                padding-top: 1rem;
                cursor: pointer;
                div{
                    margin-top: 10.5rem;
                    background: #007000;
                }
            }
        }
    }
}

.review-comments{
    border-top: 1px solid;
    padding-top: 1rem;
    padding-left: 1rem;
    max-height: 30vh;
    overflow: scroll;
    overflow-x: hidden;
    display: none;
    transition: ease-in-out 5s;
    transform:translateY(33);
    .rating{
        color: #007000;
        font-size: .3rem;
    }
    .inputComment{
        input{
            width: 80%;
            padding: 1rem;
            border: none;
            border-bottom: 1px solid;
            background: none;
            &:focus{
                outline: none;
            }
        }
        button{
            padding: .4rem;
            border: none;
            border-radius: 5px;
            background: #CAFFCA;
            // margin-left: -1rem;
        }
    }
}

#comment-toggle{
    display: none;

    &:checked ~ .review-comments{
        display: block;
    }
}


.singleComment{
    list-style: none;
    margin: .3rem;
    // background-color: #007000;
    padding: .8rem 0;
    border: 1px solid #CAFFCA;
    border-radius: 3px;
    display: flex;
    div{
        width: 1.8rem;
        height: 1.8rem;
        padding: .3rem .5rem;
        // font-size: 3rem;
        border-radius: 50%;
        margin:0 1rem;
        background-color: #007000;
        color: white;
    }
}

.writeReviewModal{
    padding-top: 10%;
    .reviewContainer{
        padding: 2.5rem;
        .reviewHeader{
            border: none;
        }

        .review-text-area{
            min-height:5vh ;
            height: auto;
            padding:1rem;
            font-size: 1.2rem;
            color: black;
            &:focus{
                outline: none;
                border: none;
            }
        }

        .modalFooter{
            display: flex;
            justify-content: space-between;
            padding-top: 1rem;
            border:none ;
            .add{
                background-color: #CAFFCA;
                color: black;
                border: none;

            }
            .cancel{
                background-color: #CB5555;
                color: black;
                border: none;
            }
          }
    }
}
