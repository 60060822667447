
h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
    font-family: 'Times New Roman', Times, serif;
}
p.sub{
    color: white;
    text-align: center;
    font-size: 1.5rem;
}

#contact-container {
    position: relative;
    width: 1100px;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    padding: 10px;
    backdrop-filter: blur(20px);
    border: 1px solid rgb((255), 255, 255, .2);

    .contact-info {
        background-color: rgb(6, 37, 12);
        // background-color:#007000;
        flex: 0 1 40%;
        border-radius: 15px;
        padding: 40px;
        color: white;
        display: flex;
        flex-direction: column;
 
        h4{
            font-size: 2rem;
            font-weight: 500;
            margin-top: 0%;
        }
        p{
            color: rgb((255), 255, 255, .7);
            font-weight: 300;
            font-size: 1rem;
            margin-bottom: 2rem;
        }

        .icon-text{

            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 2rem;

            .fas {
                font-size: 1.8rem;
                margin-right: 1.2rem;
            }
            span {
                margin-right: 2rem;
                font-size: 1.1rem;
                font-weight: 300;
            }
        }

       
    }

    form {
        padding: 40px;
        width: 100%;
        .col {
            display: flex;
            width: 100%;
            flex: 0 0 100%;
        }
        .form-group{
            display: flex;
            justify-content: flex-start ;
            flex-direction: column;
            flex: 0 1 100%;
            padding-right: 40px;
            label {
                font-size: .9;
                color: white;
                margin-bottom: 2rem;
            }
            input[type="text"], input[type="country"], input[type="email"], textarea{
                // background: rgb((255), 255, 255, .2);
                // margin-bottom: 2rem;
                border: none;
                cursor: pointer;
                padding: 10px;
                font-size: 1.5rem;
                font-weight: 300;
                color: white;
                background: transparent;
                border-bottom: 2px solid rgb((255), 255, 255, .4);
                transition: all .3 ease-in-out;
                &:focus{
                    border: none;
                    outline: none;
                    border-bottom: 2px solid #05f7ff;
                }
            }
            &:nth-child(2){
                padding-right: 0;
            }
            &.right{
                align-items: flex-end;
            }
            button {
                width: 200px;
                height: 60px;
                cursor: pointer;
                background: white;
                border: none;
                text-transform: uppercase;
                border-radius: 10px;
            }
        }
    }
}